export const API_URL = 'https://api-dev.lesbonsjours.fr/api';
//export const API_URL = 'https://api-dev.gomojo.fr/api';
//export const API_URL = 'http://localhost:3000/api';

/* auth.js */
export const LOGIN = `${API_URL}/auth/login/admin`;
export const LOGOUT = `${API_URL}/auth/logout`;
export const LOGIN_JWT = `${API_URL}/auth/loginjwt/admin`;
export const VERIFY_REMINDER = `${API_URL}/auth/reminder/verify`;

export const GENERATE_LINK = `${API_URL}/auth/generateLink`;
/* user */
export const USER = `${API_URL}/user`;

export const HISTORIQUE = `${API_URL}/historique`;

export const IBAN = `${API_URL}/iban`;

export const WAITCASHOUT = `${API_URL}/waitcashout`;

export const SPONSOR = `${API_URL}/sponsor`;

export const CHEST = `${API_URL}/chest`;

export const SESSION = `${API_URL}/session`;

export const SHARE = `${API_URL}/share`;

export const GLOBALGAMESTAT = `${API_URL}/globalgamestat`;

export const CONTENT = `${API_URL}/content`;

export const ALERT = `${API_URL}/alert`;

export const BAN = `${API_URL}/ban`;

export const EMAIL_HASH = `${API_URL}/admin/emailhash`;
export const ALL_EXTRACT = `${API_URL}/admin/extractall`;
export const GO_KADO = `${API_URL}/cartdata`;
export const ADMIN = `${API_URL}/admin`;
export const REDCHECK = `${API_URL}/redcheck`;
export const GOLOTO = `${API_URL}/goloto`;
export const GOCASH = `${API_URL}/gocash`;
/* json header options */
export const JSON_HEADER = {
	headers: {
		"Content-Type": "application/json"
	}
};